import React, { useEffect } from "react";
import {
  LdsTile,
  LdsTable,
  LdsCheckbox,
  LdsIcon,
  LdsButton,
  LdsPagination,
  LdsTextField,
  LdsLoadingSpinner,
  LdsTooltip,
  LdsSwitch,
} from "@elilillyco/ux-lds-react";
import { useState } from "react";
import {
  LdsModal,
  useLdsModal,
  useToastContext,
} from "@elilillyco/ux-lds-react";
import HeaderNav from "../HeaderNav";
import "../../assets/components/OMMS/overview.scss";
import { fetchCommonTactic } from "../../store/Components/OMMS/commonTactic";
import {
  fetchVendorData,
  ExportVendorData,
} from "../../store/Components/OMMS/overview";
import { useDispatch, useSelector } from "react-redux";
import { updateArchive } from "../../store/Components/OMMS/archiveputStore";
import { fetchMetaData } from "../../store/Components/OMMS/addtactic";
import { fetchOptions } from "../../store/Components/OMMS/getoptions";
import HistoryModal from "../shared/HistoryModal";
import ExportDataCSV from "../../data/utils/ExportDataCSV";
import Select from "react-select";

export default function Overview(props) {
  const [statusSearchFld1, setIsON1] = React.useState(false);
  const [statusSearchFld2, setIsON2] = React.useState(false);
  const [statusSearchFld3, setIsON3] = React.useState(false);
  const [statusSearchFld4, setIsON4] = React.useState(false);
  const [statusSearchFld5, setIsON5] = React.useState(false);
  const [statusSearchFld6, setIsON6] = React.useState(false);
  const [statusSearchFld7, setIsON7] = React.useState(false);
  const [statusSearchFld8, setIsON8] = React.useState(false);
  const [statusSearchFld9, setIsON9] = React.useState(false);
  const [statusSearchFld10, setIsON10] = React.useState(false);
  const [statusSearchFld11, setIsON11] = React.useState(false);
  const [statusSearchFld12, setIsON12] = React.useState(false);
  const [statusSearchFld13, setIsON13] = React.useState(false);
  const [statusSearchFld14, setIsON14] = React.useState(false);
  const [statusSearchFld15, setIsON15] = React.useState(false);
  const [statusSearchFld16, setIsON16] = React.useState(false);
  const [statusSearchFld17, setIsON17] = React.useState(false);
  const [statusSearchFld18, setIsON18] = React.useState(false);
  const [statusSearchFld19, setIsON19] = React.useState(false);
  const [statusSearchFld20, setIsON20] = React.useState(false);
  const loader = useSelector(({ overview }) => overview.loader);
  const data = useSelector(({ overview }) => overview.vendorData);
  const tPages = useSelector(({ overview }) => overview.totalPages);
  const role2 = useSelector(({ user }) => user.role_ids);
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const { addToast } = useToastContext();
  const { isModalOpen: isAuditModalOpen, setIsModalOpen: setAuditIsModalOpen } =
    useLdsModal();
  const defaultRole = useSelector(({ user }) => user.defaultRole);
  const dispatch = useDispatch();
  const [colName, setColName] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [openedEntry, setOpenedEntry] = useState();
  const [loaderNew, setLoaderNew] = useState(false);
  const [getNoOfRec, setNoOfRec] = useState(10)
  let cData = useSelector(
    ({ commonTacticSlice }) => commonTacticSlice.commonData
  );
  const [commonData, setcommonData] = useState();
  // console.log(commonData, "commonDATA");
  const commonLoader = useSelector(
    ({ commonTacticSlice }) => commonTacticSlice.commonLoader
  );
  const [curTacticId, setCurTacticId] = useState(null);
  const [isData, setIsData] = useState([]);
  const [see, setSee] = useState(false);
  const [getId, setId] = useState([]);
  const OverviewOpts = useSelector((state) => state.OptionsSlice.Options);
  const [deliverySource, setDeliverySource] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [deliverySourceType, setDeliverySourceType] = useState("");
  const [engagementBasedTactic, setEngagementBasedTactic] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [deliveryChannel, setDeliveryChannel] = useState("");
  const [platform, setPlatform] = useState("");
  const [contactType, setContactType] = useState("");
  // const [vehicle, setVehicle] = useState("");
  const [IsMetricCap, setisMetricCap] = useState();
  const [isPld, setIsPld] = useState();
  const [isRm, setIsRm] = useState();
  const [isDigital, setisDigital] = useState();
  const [getstatusdeliverysource, setStatusDeliverySource] = useState();
  const [getstatusplatform, setStatusPlatform] = useState();
  const [deliverysourceinput, setdeliverysourceinput] = useState("");
  const [platforminput, setplatforminput] = useState("");
  const [isCAP, setIsCAP] = useState(false);
  const cascading_loader = useSelector(
    ({ OptionsSlice }) => OptionsSlice.loader
  );
  // console.log("isdigi,ismcap,isrm,ispld", isDigital, IsMetricCap, isRm, isPld);
  useEffect(() => {
    setcommonData(cData);
  }, [cData]);
  const HandleSetDeliverySource = (e) => {
    setdeliverysourceinput(e);
    setStatusDeliverySource(true);
  };
  const HandleSetPlatform = (e) => {
    setplatforminput(e);
    setStatusPlatform(true);
  };
  const handleisPldToggle = (e) => {
    setIsPld(e.target.checked === true ? "TRUE" : "FALSE");
  };
  const handleisRmToggle = (e) => {
    setIsRm(e.target.checked === true ? "TRUE" : "FALSE");
  };
  
  useEffect(() => {
    if (isRm === "yes" && isPld === "yes") {
      setisMetricCap("yes");
    } else {
      setisMetricCap("no");
    }
  }, [isRm, isPld]);

  const handleisDigitalToggle = (e) => {
    setisDigital(e.target.checked === true ? "TRUE" : "FALSE");
  };
  const [NewMetricModal, setNewMetricModal] = useState();
  const [newMetric, setNewMetric] = useState(false);
  const [addNew, setAddNew] = useState(false);
  useEffect(() => {
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: 1,
        pageSize: getNoOfRec,
        deliverySource: deliverySource,
        deliveryChannel: deliveryChannel,
        deliverySourceType: deliverySourceType,
        engagementBasedTactic: engagementBasedTactic,
        vehicle: vehicle,
        supplierName: supplierName,
        platform: platform,
        contactType: contactType,
        role: defaultRole,
        page: "overview",
      })
    );
  }, [isCAP, getNoOfRec]);
  useEffect(() => {
    setIsData([...data]);
  }, [data]);
  function searchFld() {
    setCurrentPage(1);
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: 1,
        pageSize: getNoOfRec,
        deliverySource: deliverySource.trim(),
        deliveryChannel: deliveryChannel.trim(),
        deliverySourceType: deliverySourceType.trim(),
        engagementBasedTactic: engagementBasedTactic.trim(),
        supplierName: supplierName.trim(),
        vehicle: vehicle.trim(),
        platform: platform.trim(),
        contactType: contactType.trim(),
        role: defaultRole.trim(),
        page: "overview",
      })
    );
  }
  function editExistingData(parValues) {
    window.location.href = `/omms/tactic-entry?type=${parValues.type}&delivery_source=${parValues.dSource}&delivery_source_type=${parValues.dSType}&platform=${parValues.platform}&supplier_name=${parValues.sName}&delivery_channel=${parValues.dChannel}&contact_type=${parValues.cType}&engagement_based_tactic=${parValues.eTactic}&delivery_source_code=${parValues.dSCode}&is_cap=${parValues.iscap}&is_rm=${parValues.isRm}&is_pld=${parValues.isPld}&is_digital=${parValues.isDigital}&approval_status=${parValues.aStatus}&vehicle=${parValues?.vehicle}`;
  }
  const [lastEvent, setLastEvent] = useState("");
  const totalPages = tPages;
  const [currentPage, setCurrentPage] = useState(1);
  const records = data;

  const clickLink = (e, value) => {
    setLastEvent(`clickLink, value: ${value}`);
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: value,
        pageSize: getNoOfRec,
        deliverySource: deliverySource,
        deliveryChannel: deliveryChannel,
        deliverySourceType: deliverySourceType,
        engagementBasedTactic: engagementBasedTactic,
        supplierName: supplierName,
        platform: platform,
        vehicle: vehicle,
        contactType: contactType,
        role: defaultRole,
        page: "overview",
      })
    );
  };
  const lastPage = () => {
    setCurrentPage(tPages);
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: tPages,
        pageSize: getNoOfRec,
        deliverySource: deliverySource,
        deliveryChannel: deliveryChannel,
        deliverySourceType: deliverySourceType,
        engagementBasedTactic: engagementBasedTactic,
        supplierName: supplierName,
        platform: platform,
        vehicle: vehicle,
        contactType: contactType,
        role: defaultRole,
        page: "overview",
      })
    );
  };
  const firstPage = () => {
    setCurrentPage(1);
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: 1,
        pageSize: getNoOfRec,
        deliverySource: deliverySource,
        deliveryChannel: deliveryChannel,
        deliverySourceType: deliverySourceType,
        engagementBasedTactic: engagementBasedTactic,
        supplierName: supplierName,
        platform: platform,
        vehicle: vehicle,
        contactType: contactType,
        role: defaultRole,
        page: "overview",
      })
    );
  };
  const clickNext = (e, value) => {
    setLastEvent(`clickNext, value: ${value}`);
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: currentPage + 1,
        pageSize: getNoOfRec,
        deliverySource: deliverySource,
        deliveryChannel: deliveryChannel,
        deliverySourceType: deliverySourceType,
        engagementBasedTactic: engagementBasedTactic,
        supplierName: supplierName,
        platform: platform,
        vehicle: vehicle,
        contactType: contactType,
        role: defaultRole,
        page: "overview",
      })
    );
  };

  const clickPrev = (e, value) => {
    setLastEvent(`clickPrev, value: ${value}`);
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: currentPage - 1,
        pageSize: getNoOfRec,
        deliverySource: deliverySource,
        deliveryChannel: deliveryChannel,
        deliverySourceType: deliverySourceType,
        engagementBasedTactic: engagementBasedTactic,
        supplierName: supplierName,
        platform: platform,
        vehicle: vehicle,
        contactType: contactType,
        role: defaultRole,
        page: "overview",
      })
    );
  };

  function checkMetaDataRole() {
    const role = sessionStorage.getItem("role");
    if (
      role === "omms_admin" ||
      role === "omt_admin" ||
      role === "omms_metadata_manager"
    ) {
      return true;
    }
    return false;
  }

  function checkTacticRole() {
    const role = sessionStorage.getItem("role");
    if (
      role === "omms_admin" ||
      role === "omt_admin" ||
      role === "omms_vendor" ||
      role === "omms_metadata_manager" ||
      role === "omms_peer_review_bia" ||
      role === "omms_peer_review_channel" ||
      role === "omms_peer_review_campaign"
    ) {
      return true;
    }
    return false;
  }

  function checkApprovalRole() {
    const role = sessionStorage.getItem("role");
    if (
      role === "omms_admin" ||
      role === "omms_peer_review_bia" ||
      role === "omms_peer_review_channel" ||
      role === "omms_peer_review_campaign" ||
      role === "omt_admin"
    ) {
      return true;
    }
    return false;
  }

  function checkArchiveRole() {
    const role = sessionStorage.getItem("role");
    if (role === "omms_admin" || role === "omt_admin") {
      return true;
    }
  }

  function checkExceptionRole() {
    const role = sessionStorage.getItem("role");
    if (
      role === "omms_admin" ||
      role === "omms_peer_review_bia" ||
      role === "omt_admin"
    ) {
      return true;
    }
  }
  function checkEditRole() {
    const role = sessionStorage.getItem("role");
    if (
      role === "omms_admin" ||
      role === "omt_admin" ||
      role === "omms_vendor" ||
      role === "omms_metadata_manager" ||
      role === "omms_peer_review_bia" ||
      role === "omms_peer_review_channel" ||
      role === "omms_peer_review_campaign"
    ) {
      return true;
    }
  }
  useEffect(() => {
    if (addNew || newMetric === false) {
      setIsPld();
      setIsRm();
      setdeliverysourceinput();
      setplatforminput();
      setisDigital();
      setisMetricCap();
      setcommonData();
      clearSearch();
    }
  }, [addNew, newMetric]);
  useEffect(() => {
    dispatch(fetchMetaData()).then((response) =>
      setNewMetricModal(response?.payload?.data)
    );
  }, [newMetric, isCAP]);
  let queryParams = "";
  useEffect(() => {
    queryParams = "";

    if (deliverysourceinput !== "" && deliverysourceinput !== undefined) {
      queryParams += `deliverySource=${deliverysourceinput?.value}`;
    }
    if (platforminput !== "" && platforminput !== undefined) {
      queryParams += `&platform=${platforminput?.value}`;
    }
    if (queryParams === "") {
    } else {
      dispatch(fetchOptions(queryParams));
    }
  }, [deliverysourceinput, platforminput, isCAP]);
  const [platformValuesfromAPI, setplatformVlaues] = useState([]);
  const [delivery_SourcefromAPI, setdelivery_SourcefromAPI] = useState([]);

  const platformValues = [];
  const delivery_sourceValues = [];
  useEffect(() => {
    NewMetricModal?.forEach((item) => {
      if (item.attributeType === "platform") {
        platformValues.push(item.attributeValue);
      } else if (item.attributeType === "delivery_source") {
        delivery_sourceValues.push(item.attributeValue);
      }
    });
    setplatformVlaues(platformValues);
    setdelivery_SourcefromAPI(delivery_sourceValues);
  }, [NewMetricModal]);
  const labelValuePairforDeliverySource = delivery_SourcefromAPI
    .map((item) => ({ label: item, value: item }))
    .sort((a, b) => a.label.localeCompare(b.label));

  function addtactic() {
    window.location.href = "/omms/tactic-entry";
  }
  function addEditMetadata() {
    window.location.href = "/omms/add-edit-metadata";
  }
  function archive() {
    window.location.href = "/omms/archive";
  }
  function reqforapproval() {
    window.location.href = "/omms/requestforapproval";
  }
  function exceptionReporting() {
    window.location.href = "/omms/exception-reporting";
  }
  function handleClick() {
    setIsModalOpen(false);
  }

  function modalOpen() {
    if (getId.length === 0) {
      addToast({
        toastMessage: "Please select atleast one record ",
        actionText: "",
        variant: "warning",
        position: "top",
        align: "center",
        dismissible: true,
        light: false,
        timeout: 5000,
        autoDismiss: true,
        zIndex: 11,
      });
      return;
    }
    setIsModalOpen(true);
  }

  const downloadExcel = () => {
    dispatch(
      ExportVendorData({
        deliverySource: deliverySource,
        deliveryChannel: deliveryChannel,
        deliverySourceType: deliverySourceType,
        engagementBasedTactic: engagementBasedTactic,
        supplierName: supplierName,
        platform: platform,
        vehicle: vehicle,
        contactType: contactType,
        columnType: colName,
        role: defaultRole,
        page: "overview",
      })
    ).then((data) => {
      ExportDataCSV(data.payload.data, "OMMS_CampaignList");
    });
  };

  async function toArchivePage() {
    setLoaderNew(true);
    const currentRole = sessionStorage.getItem("role");

    const currentUserData = role2.filter(
      (roleData) => Object.keys(roleData)[0] === currentRole
    );
    const updated_by = currentUserData[0][currentRole];
    await dispatch(
      updateArchive({
        omms_overview_detail_id: getId,
        status: "active",
        updated_by,
      })
    );
    // await dispatch(fetchVendorData({
    // pageNumber: 1,
    // pageSize: getNoOfRec,
    // deliverySource: deliverySource,
    // deliveryChannel: deliveryChannel,
    // deliverySourceType: deliverySourceType,
    // supplierName: supplierName,
    // platform: platform,
    // contactType: contactType,
    // role: defaultRole,
    // page: 'overview',
    // }));
    await dispatch(
      fetchCommonTactic({
        delivery_source: openedEntry.dSource,
        supplier_name: openedEntry.sName,
        delivery_source_type: openedEntry.dSType,
        delivery_channel: openedEntry.dChannel,
        platform: openedEntry.platform,
        contact_type: openedEntry.cType,
        engagement_based_tactic: openedEntry.eTactic,
        vehicle : vehicle,
        approval_status: openedEntry.aStatus,
        iscap: openedEntry.iscap === "yes" ? true : false,
      })
    );
    addToast({
      toastMessage: "Your changes have been saved successfully",
      actionText: "",
      variant: "success",
      position: "top",
      align: "center",
      dismissible: true,
      light: false,
      timeout: 2000,
      inline: false,
      autoDismiss: true,
      zIndex: 11,
    });

    setLoaderNew(false);
  }

  function handleMainChange(e) {
    if (e.target.checked) {
      setId(commonData.map((obj) => obj.omms_overview_detail_id));
    }
    if (!e.target.checked) {
      setId([]);
    }
  }

  const checkedData = (e) => {
    if (e.target.checked) {
      if (!getId.includes(parseInt(e.target.value))) {
        setId([...getId, parseInt(e.target.value)]);
      }
    }
    if (!e.target.checked) {
      if (getId.includes(parseInt(e.target.value))) {
        setId(getId.filter((obj) => obj !== parseInt(e.target.value)));
      }
    }
  };

  const [srchkey, setSrchKey] = useState({
    metricLabel: "",
    engagementLabel: "",
    hierarchyClassification: "",
    metricDefinition: "",
  });
  const searchOP = (cData) => {
    const filteredData = cData.filter((item) => {
      return Object.keys(srchkey).every((key) => {
        // If the search field is empty, return true (don't filter it out)
        if (srchkey[key] === "") return true;
        // Split the search string into an array of values
        const searchValues = srchkey[key].toUpperCase().split(",");
        // If the data point includes any of the search values, return true (don't filter it out)
        return (
          item[key] &&
          searchValues.some((value) =>
            item[key].toString().toUpperCase().includes(value.trim())
          )
        );
      });
    });

    setcommonData(filteredData);
  };

  const clearSub = () => {
    setIsON6(false);
    setIsON5(false);
    setIsON7(false);
    setIsON8(false);
    setIsON9(false);
    setIsON10(false);
    setIsON11(false);
    setIsON12(false);
    setIsON13(false);
    setIsON14(false);
    setIsON15(false);
    setIsON16(false);
    setIsON17(false);
    setcommonData(cData);
  };
  const [parValues, setParValues] = useState({});
  const commonvalueCheck = (params) => {
    setOpenedEntry(params);
    // console.log(params.aStatus, 'astat')
    dispatch(
      fetchCommonTactic({
        delivery_source: params.dSource,
        supplier_name: params.sName,
        delivery_source_type: params.dSType,
        engagement_based_tactic: params.eTactic,
        delivery_channel: params.dChannel,
        platform: params.platform,
        contact_type: params.cType,
        vehicle: params.vehicle,
        approval_status: params.aStatus,
        iscap: params.iscap === "yes" ? true : false,
      })
    ).then((data) => setcommonData(data?.payload?.data));
    setParValues({ ...params });
    // console.log(parValues, 'pval')
  };
  useEffect(() => {
    newMetric &&
      platforminput &&
      dispatch(
        fetchVendorData({
          is_cap:isCAP,
          pageNumber: 1,
          pageSize: getNoOfRec,
          deliverySource: deliverysourceinput?.value,
          platform: platforminput?.value,
          deliveryChannel: "",
          deliverySourceType: "",
          engagementBasedTactic: "",
          supplierName: "",
          contactType: "",
          vehicle: vehicle,
          role: defaultRole,
          page: "overview",
        })
      ).then(
        (data) =>
          data?.payload?.statusCode === 200 &&
          dispatch(
            fetchCommonTactic({
              delivery_source: deliverysourceinput?.value,
              supplier_name: data?.payload?.data?.[0]?.supplierName,
              delivery_source_type:
                data?.payload?.data?.[0]?.deliverySourceType,
              engagement_based_tactic: data?.payload?.data?.[0]?.engagementBasedTactic,
              delivery_channel: data?.payload?.data?.[0]?.deliveryChannel,
              platform: platforminput?.value,
              vehicle: data?.payload?.data?.[0]?.vehicle,
              contact_type: data?.payload?.data?.[0]?.contactType,
              approval_status: data?.payload?.data?.[0]?.approvalStatus,
              iscap: data?.payload?.data?.[0]?.isCap,
            })
          )
      );
  }, [platforminput, isCAP]);
  useEffect(() => {
    setIsPld(commonData?.[0]?.isPld === true ? "TRUE" : "FALSE");
    setisDigital(commonData?.[0]?.isDigital === true ? "TRUE" : "FALSE");
    setIsRm(commonData?.[0]?.isRm === true ? "TRUE" : "FALSE");
  }, [commonData, getNoOfRec]);

  function editExistingMetricData(parValues) {
    window.location.href = `/omms/tactic-entry?type=add&delivery_source=${deliverysourceinput?.value}&delivery_source_type=${OverviewOpts?.deliverySourceType?.[0]?.options?.[0]?.value}
    &engagement_based_tactic=${OverviewOpts?.engagementBasedTactic?.[0]?.options?.[0]?.value}&platform=${platforminput?.value}&supplier_name=${OverviewOpts?.supplierName?.[0]?.options?.[0]?.value}&delivery_channel=${OverviewOpts?.deliveryChannel?.[0]?.options?.[0]?.value}&vehicle=${OverviewOpts?.vehicle?.[0]?.options?.[0]?.value}
    &contact_type=${OverviewOpts?.contactType?.[0]?.options?.[0]?.value}&is_cap=${IsMetricCap}&is_rm=${isRm}&is_pld=${isPld}&is_digital=${isDigital}&vehicle=${vehicle}`;
  }
  const clearSearch = () => {
    setIsON1(false);
    setIsON2(false);
    setIsON3(false);
    setIsON4(false);
    setIsON5(false);
    setIsON6(false);
    setIsON7(false);
    setIsON8(false);
    setIsON9(false);
    setIsON10(false);
    setIsON12(false);
    setIsON11(false);
    setIsON13(false);
    setIsON14(false);
    setIsON15(false);
    setIsON16(false);
    setIsON17(false);
    setDeliverySource("");
    setDeliveryChannel("");
    setSupplierName("");
    setContactType("");
    setPlatform("");
    setDeliverySourceType("");
    setEngagementBasedTactic("");
    setVehicle("");
    setCurrentPage(1);
    dispatch(
      fetchVendorData({
        is_cap:isCAP,
        pageNumber: 1,
        pageSize: getNoOfRec,
        deliverySource: "",
        deliveryChannel: "",
        deliverySourceType: "",
        engagementBasedTactic: "",
        supplierName: "",
        platform: "",
        vehicle: "",
        contactType: "",
        role: defaultRole,
        page: "overview",
      })
    );
  };

  return (
    <>
      <HeaderNav msg={"Omnichannel Metadata Management System"} />
      <div>
        <LdsTile className="overview-hero">
          <div className="d-flex ">
            <div className="mt-2">
              <span className="overview-text">Overview</span>
              <LdsSwitch
              className="mt-4 p-2"
                id="switchDemoDefault"
                label="Available in CAP"
                onClick={(e)=>setIsCAP(e?.target?.checked)}
                value="demoDefault"
              />
            </div>
            <div className="button-container-ov">
              <LdsButton
                icon="DownloadSimple"
                className="col buttons downloadcolor"
                onClick={downloadExcel}
                
              >
                Download
              </LdsButton>
              {checkMetaDataRole() && (
                <LdsButton onClick={addEditMetadata} className="col buttons">
                  Add/Edit Metadata
                </LdsButton>
              )}
              {checkTacticRole() && (
                <LdsButton
                  onClick={() => setAddNew(true)}
                  className="col buttons"
                >
                  Add New
                </LdsButton>
              )}
              {checkApprovalRole() && (
                <LdsButton onClick={reqforapproval} className="col buttons">
                  Request For Approval
                </LdsButton>
              )}
              {checkArchiveRole() && (
                <LdsButton onClick={archive} className="col buttons">
                  View Archive
                </LdsButton>
              )}
              <LdsButton onClick={() => clearSearch()} className="col buttons">
                Clear Search
              </LdsButton>
              {checkExceptionRole() && (
                <LdsButton onClick={exceptionReporting} className="col buttons">
                  Exception Reporting
                </LdsButton>
              )}
            </div>
          </div>
        </LdsTile>
      </div>
      <LdsModal
        modalId="testModal"
        open={addNew}
        setModalOpen={() => {
          setAddNew(false);
          clearSearch();
          setNewMetric(false);
        }}
        heading={newMetric ? "Add Metric" : "What would you like to add?"}
      >
        {!newMetric ? (
          <div className="space">
            <LdsButton
              onClick={() => {
                addtactic();
              }}
            >
              New Tactic
            </LdsButton>
            <LdsButton
              onClick={() => {
                setNewMetric(true);
              }}
            >
              New Metric
            </LdsButton>
          </div>
        ) : (
          <div className="new-metric-modal container">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center  ">
                <div>
                  <div>
                    <label>Delivery Source</label>
                    <Select
                      id="delivery_source"
                      className="select-width"
                      options={labelValuePairforDeliverySource}
                      isClearable
                      closeMenuOnSelect
                      value={
                        deliverysourceinput ||
                        (getstatusdeliverysource === true
                          ? {
                            label: "Select Delivery Source",
                            value: null,
                          }
                          : deliverysourceinput
                            ? {
                              label: deliverysourceinput,
                              value: deliverysourceinput,
                            }
                            : {
                              label: "Select Delivery Source",
                              value: null,
                            })
                      }
                      onChange={(e) => {
                        HandleSetDeliverySource(e);
                      }}
                    />
                  </div>
                  <div className="mt-3">
                    <label>Platform</label>
                    <Select
                      className="select-width"
                      id="platform"
                      options={OverviewOpts?.platform?.[0]?.options}
                      isLoading={cascading_loader}
                      isClearable
                      isDisabled={cascading_loader}
                      closeMenuOnSelect
                      value={
                        platforminput ||
                        (getstatusplatform === true
                          ? { label: "Select Platform", value: null }
                          : platform
                            ? { label: platform, value: platform }
                            : { label: "Select Platform", value: null })
                      }
                      onChange={(e) => {
                        HandleSetPlatform(e);
                      }}
                    />
                  </div>
                </div>
                <LdsButton
                  className="m-5"
                  onClick={() => {
                    setIsPld();
                    setIsRm();
                    setdeliverysourceinput();
                    setplatforminput();
                    setisDigital();
                    setisMetricCap();
                    setcommonData();
                  }}
                >
                  Clear All
                </LdsButton>
              </div>
            </div>
            <div className="d-flex align-items-center  ">
              <div className="m-5">
                <span>
                  <span className="parent-h">Supplier Name: </span>{" "}
                  {deliverysourceinput &&
                    platforminput &&
                    OverviewOpts?.supplierName?.[0]?.options?.[0]?.value}
                </span>
                <br />
                <span>
                  <span className="parent-h">Contact Type: </span>{" "}
                  {deliverysourceinput &&
                    platforminput &&
                    OverviewOpts?.contactType?.[0]?.options?.[0]?.value}
                </span>
                <br />
                <span>
                  <span className="parent-h">Engagement Based Tactic: </span>{" "}
                  {deliverysourceinput &&
                    platforminput &&
                    OverviewOpts?.engagementBasedTactic?.[0]?.options?.[0]?.value}
                </span>
              </div>
              <div className="m-5">
                <span>
                  <span className="parent-h">Delivery Channel: </span>{" "}
                  {deliverysourceinput &&
                    platforminput &&
                    OverviewOpts?.deliveryChannel?.[0]?.options?.[0]?.value}
                </span>
                <br />
                <span>
                  <span className="parent-h">Delivery Source Type: </span>{" "}
                  {deliverysourceinput &&
                    platforminput &&
                    OverviewOpts?.deliverySourceType?.[0]?.options?.[0]
                      ?.value}
                </span>
                <br />
                <span>
                  <span className="parent-h">Vehicle: </span>{" "}
                  {deliverysourceinput &&
                    platforminput &&
                    OverviewOpts?.vehicle?.[0]?.options?.[0]
                      ?.value}
                </span>
              </div>
              <div>
                {commonLoader ? (
                  <LdsLoadingSpinner
                    size={40}
                    animationSpeed={250}
                    className="ml-3"
                  />
                ) : (
                  <div
                    id="switchbtn"
                    className="m-5 d-flex flex-column align-items-end"
                  >
                    {!commonData?.[0]?.isDigital && (
                      <LdsSwitch
                        className="mt-4"
                        label="Digital"
                        value={isDigital}
                        onByDefault={false}
                        onChange={(e) => {
                          handleisDigitalToggle(e);
                        }}
                      />
                    )}
                    {commonData?.[0]?.isDigital && (
                      <LdsSwitch
                        className="mt-4"
                        label="Digital"
                        disabled
                        value={commonData?.[0]?.isDigital}
                        onByDefault={commonData?.[0]?.isDigital}
                        onChange={(e) => {
                          handleisDigitalToggle(e);
                        }}
                      />
                    )}
                    {!commonData?.[0]?.isPld && (
                      <LdsSwitch
                        className="mt-4"
                        label="PLD at Tactic Level"
                        value={isPld}
                        onByDefault={false}
                        onChange={(e) => {
                          handleisPldToggle(e);
                        }}
                      />
                    )}
                    {commonData?.[0]?.isPld && (
                      <LdsSwitch
                        className="mt-4"
                        disabled
                        label="PLD at Tactic Level"
                        // value={commonData?.[0]?.isPld}
                        onByDefault={commonData?.[0]?.isPld}
                        onChange={(e) => {
                          handleisPldToggle(e);
                        }}
                      />
                    )}
                    {!commonData?.[0]?.isRm && (
                      <LdsSwitch
                        className="mt-4"
                        label="Accepts Tactic IDs in RMs"
                        value={isRm}
                        onByDefault={false}
                        onChange={(e) => {
                          handleisRmToggle(e);
                        }}
                      />
                    )}
                    {commonData?.[0]?.isRm && (
                      <LdsSwitch
                        className="mt-4"
                        disabled
                        label="Accepts Tactic IDs in RMs"
                        // value={commonData?.[0]?.isRm}
                        onByDefault={commonData?.[0]?.isRm}
                        onChange={(e) => {
                          handleisRmToggle(e);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              
              
              
            </div>
            <LdsTable className="sub-table mt-4 mb-3 ">
              <thead>
                <tr>
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">Metric Label</div>
                  </th>
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">Engagement Classification</div>
                  </th>
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">Hierarchy Classification</div>
                  </th>
                  {/* <th scope="col" className="table-ends">
                        <div class="attriDiv">Metric Definition</div>
                      </th> */}
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">Engagement Depth</div>
                  </th>
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">
                      Contract Basis Classification
                    </div>
                  </th>
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">
                      Performance Basis Classification
                    </div>
                  </th>
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">
                      Engagement Rate Calculated Against
                    </div>
                  </th>
                  <th scope="col" className="table-ends">
                    <div class="attriDiv">Status</div>
                  </th>
                </tr>
              </thead>
              {commonLoader === false ? (
                commonData && commonData.length > 0 ? (
                  <tbody>
                    {commonData.map((metric, rowIndex) => (
                      <tr role="row" key={rowIndex}>
                        <td className="table-ends">{metric.metricLabel}</td>
                        <td className="table-ends">
                          {metric.engagementClassification}
                        </td>
                        <td className="table-ends">
                          {metric.hierarchyClassification}
                        </td>
                        {/* <td className="table-ends">
                              {metric.metricDefinition}
                            </td>` */}
                        <td className="table-ends">
                          {metric.engagementDepth}
                        </td>
                        <td className="table-ends">
                          {metric.contractBasisClassification}
                        </td>
                        <td className="table-ends">
                          {metric.performanceBasisClassification}
                        </td>
                        <td className="table-ends">
                          {metric.engagementRateCalculatedAgainst}
                        </td>
                        <td className="table-ends">
                          <div>{metric.approvalStatus}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <div>
                      <span
                        className="no-data-metric         
                            "
                      >
                        No Data Available
                      </span>
                    </div>
                  </tbody>
                )
              ) : (
                <LdsLoadingSpinner
                  size={50}
                  animationSpeed={250}
                  style={{
                    zIndex: "3",
                    position: "absolute",
                    marginLeft: "45%",
                    marginTop: "3rem",
                    height: "20rem",
                  }}
                />
              )}
            </LdsTable>
            <div className="mt-5 d-flex ">
              <LdsButton
                className="m-2"
                classes="outlined"
                onClick={() => {
                  clearSearch();
                  setNewMetric(false);
                }}
              >
                Cancel
              </LdsButton>
              <LdsButton
                className="m-2"
                onClick={() => {
                  deliverysourceinput &&
                    platforminput &&
                    editExistingMetricData();
                }}
              >
                Add Metric{" "}
              </LdsButton>
            </div>
          </div>
        )}
      </LdsModal>
      {/* Metric modal */}
      <LdsModal
        modalId="Sub"
        open={see}
        setModalOpen={() => setSee()}
        heading="Metric Table"
        style={{ zIndex: 100 }}
      >
        <div className="parent-container">
          <div className="parent-row">
            <span>
              <span className="parent-h">Delivery Source: </span>
              {parValues.dSource}
            </span>
            <span>
              <span className="parent-h">Supplier Name: </span>{" "}
              {parValues.sName}
            </span>
            <span>
              <span className="parent-h">Vehicle: </span>{" "}
              {parValues.vehicle}
            </span>
          </div>
          <div className="parent-row">
            <span>
              <span className="parent-h">Platform: </span>
              {parValues.platform}
            </span>
            <span>
              <span className="parent-h">Contact Type: </span>
              {parValues.cType}
            </span>
            <span>
              <span className="parent-h"> Delivery Source Code: </span>
              {parValues.dSCode}
            </span>
          </div>
          <div className="parent-row">
            <span>
              <span className="parent-h">Delivery Channel: </span>{" "}
              {parValues.dChannel}
            </span>
            <span>
              <span className="parent-h">Delivery Source Type: </span>{" "}
              {parValues.dSType}
            </span> 
            <span>
              <span className="parent-h">Engagement Based Tactic: </span>{" "}
              {parValues.eTactic}
            </span> 
          </div>
          <div className="parent-row">
            <span>
              <span className="parent-h">Digital: </span>{" "}
              {parValues.isDigital}
            </span>
            <span>
              <span className="parent-h">PLD: </span>{" "}
              {parValues.isPld}
            </span> 
            <span>
              <span className="parent-h">RM: </span>{" "}
              {parValues.isRm}
            </span> 
          </div>
          <div className="parent-row">
            {checkEditRole() && (
              <LdsButton
                className="sub-icon"
                name="PencilSimpleLineFill"
                label="edit"
                onClick={() => {
                  editExistingData({ ...parValues, type: "edit" });
                }}
              >
                Edit
              </LdsButton>
            )}
            {checkEditRole() && (
              <LdsButton
                className="sub-icon"
                name="PencilSimpleLineFill"
                label="edit"
                onClick={() => {
                  editExistingData({ ...parValues, type: "add" });
                }}
              >
                Add Metric
              </LdsButton>
            )}
            <LdsButton
              className="sub-icon"
              name="PencilSimpleLineFill"
              label="edit"
              onClick={() => {
                clearSub();
              }}
            >
              Clear
            </LdsButton>
          </div>
        </div>
        {/* Metric Table */}
        <LdsTable className="sub-table mt-4 mb-3 ">
          <thead>
            <tr>
              {checkArchiveRole() && (
                <th className="table-ends" scope="col">
                  <LdsCheckbox
                    name="opt1"
                    onChange={(e) => {
                      handleMainChange(e);
                    }}
                    style={{ marginTop: "-1.5rem" }}
                  />
                </th>
              )}
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Metric Label
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON6(!statusSearchFld6);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld6 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="metricLabel"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value,
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Engagement Classification
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON5(!statusSearchFld5);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld5 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="engagementClassification"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Hierarchy Classification
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON7(!statusSearchFld7);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld7 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="hierarchyClassification"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              {/* <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Metric Definition
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON10(!statusSearchFld10);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld10 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="metricDefinition"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th> */}

              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Engagement Depth
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON12(!statusSearchFld12);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld12 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="engagementDepth"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Contract Basis Classification
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON13(!statusSearchFld13);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld13 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="contractBasisClassification"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Performance Basis Classification
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON14(!statusSearchFld14);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld14 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="performanceBasisClassification"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Engagement Rate Calculated Against
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON15(!statusSearchFld15);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld15 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="engagementRateCalculatedAgainst"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Distinct Count Of Hcp
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON18(!statusSearchFld18);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld18 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="distinctCountOfHcp"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Total Sum Of Metric Count
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON19(!statusSearchFld19);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld19 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="totalSumOfMetricCount"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>
              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Max Activity Date
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON20(!statusSearchFld20);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld20 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="maxActivityDate"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>

              <th scope="col" className="table-ends">
                <div class="attriDiv">
                  Status
                  <div
                    class="iconStyle"
                    style={{
                      display: "flex",
                      alignItems: "initial",
                      maxHeight: "1rem",
                      marginTop: "0.3rem",
                    }}
                  >
                    <LdsIcon
                      description="MagnifyingGlass"
                      label="search"
                      name="MagnifyingGlass"
                      class="icon-size"
                      style={{
                        height: "15px",
                        marginBottom: "-2rem",
                        maxWidth: "2.5rem",
                        minWidth: "2.5rem",
                      }}
                      onClick={() => {
                        setIsON11(!statusSearchFld11);
                      }}
                    />
                  </div>
                </div>
                {statusSearchFld11 && (
                  <div style={{ width: "12rem" }}>
                    <LdsTextField
                      className="text-fld"
                      id="status"
                      onChange={(e) =>
                        setSrchKey({
                          ...srchkey,
                          [e.target.id]: e.target.value
                            .replace(/\s*,\s*/g, ",")
                            .replace(/\s+/g, " "),
                        })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          searchOP(cData);
                        }
                      }}
                    />
                  </div>
                )}
              </th>

              <th className="table-ends" scope="col">
                History
              </th>
            </tr>
          </thead>
          {commonLoader === false && !loaderNew ? (
            commonData && commonData.length > 0 ? (
              <tbody>
                {commonData.map((entry, rowIndex) => (
                  <tr role="row" key={rowIndex}>
                    {checkArchiveRole() && (
                      <td className="table-ends">
                        <LdsCheckbox
                          name="opt1"
                          id={entry.omms_overview_detail_id}
                          onChange={(e) => {
                            checkedData(e);
                          }}
                          value={entry.omms_overview_detail_id}
                          checked={getId.includes(
                            parseInt(entry.omms_overview_detail_id)
                          )}
                        />
                      </td>
                    )}
                    <td className="table-ends">
                    <div>
                          <LdsTooltip hideIcon edgePadding="8" constrainToParent="false">
                            <LdsTooltip.Text>
                              {entry.metricLabel}
                            </LdsTooltip.Text>
                            {entry.metricDefinition ? (
                              <LdsTooltip.Description>
                                {entry.metricDefinition}
                              </LdsTooltip.Description>
                            ) : (
                              <LdsTooltip.Description>
                                N/A
                              </LdsTooltip.Description>
                            )}
                          </LdsTooltip>
                        </div>
                    </td>
                    <td className="table-ends">
                      {entry.engagementClassification}
                    </td>
                    <td className="table-ends">
                      {entry.hierarchyClassification}
                    </td>
                    
                    <td className="table-ends">{entry.engagementDepth}</td>
                    <td className="table-ends">
                      {entry.contractBasisClassification}
                    </td>
                    <td className="table-ends">
                      {entry.performanceBasisClassification}
                    </td>
                    <td className="table-ends">
                      {entry.engagementRateCalculatedAgainst}
                    </td>
                    <td className="table-ends">
                      {entry.distinctCountOfHcp}
                    </td>
                    <td className="table-ends">
                      {entry.totalSumOfMetricCount}
                    </td>
                    <td className="table-ends">
                      {entry.maxActivityDate}
                    </td>

                    <td className="table-ends">
                      {entry.approvalStatus === "REJECTED" ? (
                        <div>
                          <LdsTooltip hideIcon>
                            <LdsTooltip.Text>
                              {entry.approvalStatus}
                            </LdsTooltip.Text>
                            {entry.remarks ? (
                              <LdsTooltip.Description>
                                {entry.remarks}
                              </LdsTooltip.Description>
                            ) : (
                              <LdsTooltip.Description>
                                No remarks found!
                              </LdsTooltip.Description>
                            )}
                          </LdsTooltip>
                        </div>
                      ) : (
                        <div>{entry.approvalStatus}</div>
                      )}
                    </td>
                    <td className="table-ends">
                      <div className="icon-col">
                        <LdsIcon
                          className="table-icons"
                          name="InfoFill"
                          onClick={() => {
                            setAuditIsModalOpen(true);
                            setCurTacticId(entry.omms_overview_detail_id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <div>
                  <span className="no-data">No Data Available</span>
                </div>
              </tbody>
            )
          ) : (
            <LdsLoadingSpinner
              size={50}
              animationSpeed={500}
              style={{
                zIndex: "3",
                position: "absolute",
                marginLeft: "45%",
                marginTop: "3rem",
                height: "20rem",
              }}
            />
          )}
        </LdsTable>
        <div>
          {checkArchiveRole() && (
            <div className="restoreButton">
              <LdsButton onClick={() => modalOpen()}>Archive</LdsButton>
              <LdsModal
                modalId="testModal"
                open={isModalOpen}
                setModalOpen={setIsModalOpen}
                heading="Are you sure you want to archive this record?"
              >
                <div className="space">
                  <LdsButton
                    onClick={() => {
                      toArchivePage();
                      handleClick();
                    }}
                  >
                    Yes
                  </LdsButton>
                  <LdsButton
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    No
                  </LdsButton>
                </div>
              </LdsModal>
            </div>
          )}
        </div>
      </LdsModal>

      <LdsTable >
        <thead>
          <tr>
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Delivery Source
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON1(!statusSearchFld1);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld1 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={deliverySource}
                    onChange={(e) =>
                      setDeliverySource(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Supplier Name
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON2(!statusSearchFld2);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld2 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={supplierName}
                    onChange={(e) =>
                      setSupplierName(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Platform
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON3(!statusSearchFld3);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld3 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={platform}
                    onChange={(e) =>
                      setPlatform(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Delivery Channel
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON9(!statusSearchFld9);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld9 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={deliveryChannel}
                    onChange={(e) =>
                      setDeliveryChannel(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Contact Type
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON4(!statusSearchFld4);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld4 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={contactType}
                    onChange={(e) =>
                      setContactType(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Delivery Source Type
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON8(!statusSearchFld8);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld8 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={deliverySourceType}
                    onChange={(e) =>
                      setDeliverySourceType(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Engagement Based Tactic
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON16(!statusSearchFld16);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld16 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={engagementBasedTactic}
                    onChange={(e) =>
                      setEngagementBasedTactic(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            <th scope="col" className="table-ends">
              <div class="attriDivCore">
                Vehicle
                <div
                  class="iconStyle"
                  style={{
                    display: "flex",
                    alignItems: "initial",
                    maxHeight: "1rem",
                    marginTop: "0.3rem",
                  }}
                >
                  <LdsIcon
                    description="MagnifyingGlass"
                    label="search"
                    name="MagnifyingGlass"
                    class="icon-size"
                    style={{
                      height: "15px",
                      marginBottom: "-2rem",
                      maxWidth: "2.5rem",
                      minWidth: "2.5rem",
                    }}
                    onClick={() => {
                      setIsON17(!statusSearchFld17);
                    }}
                  />
                </div>
              </div>
              {statusSearchFld17 && (
                <div style={{ width: "12rem" }}>
                  <LdsTextField
                    className="text-fld"
                    id="orgName"
                    value={vehicle}
                    onChange={(e) =>
                      setVehicle(
                        e.target.value
                          .replace(/\s*,\s*/g, ",")
                          .replace(/\s+/g, " ")
                      )
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchFld();
                      }
                    }}
                    name="orgName"
                  />
                </div>
              )}
            </th>
            <th className="table-ends" scope="col">
            Available in CAP
            </th>
          </tr>
        </thead>
        {!loader && !commonLoader ? (
          data && data.length > 0 ? (
            <tbody style={{ zIndex: "1" }}>
              {isData.map((entry, rowIndex) => (
                <tr
                  role="row"
                  key={rowIndex}
                  className={`table-ends ${entry.approvalStatus === "REJECTED" ? "rejected" : ""
                    }`}
                >
                  <td className="table-ends">
                    <div
                      className="table-click"
                      onClick={() => {
                        setSee(true);
                        commonvalueCheck({
                          dSource: entry.deliverySource,
                          sName: entry.supplierName,
                          dSType: entry.deliverySourceType,
                          dSCode: entry.deliverySourceCode,
                          eTactic: entry.engagementBasedTactic,
                          dChannel: entry.deliveryChannel,
                          platform: entry.platform,
                          cType: entry.contactType,
                          aStatus: entry.approvalStatus,
                          vehicle: entry?.vehicle,
                          iscap: entry.isCap === true ? "yes" : "no",
                          isRm: entry.isRm === true ? "TRUE" : "FALSE",
                          isPld: entry.isPld === true ? "TRUE" : "FALSE",
                          isDigital: entry.isDigital === true ? "TRUE" : "FALSE",
                        });
                      }}
                    >
                      <div>
                        <LdsIcon className="p-icon" name="PlusCircle" />
                      </div>
                      {entry.deliverySource}
                    </div>
                  </td>
                  <td className="table-ends">{entry.supplierName}</td>
                  <td className="table-ends">{entry.platform}</td>
                  <td className="table-ends">{entry.deliveryChannel}</td>
                  <td className="table-ends">{entry.contactType}</td>
                  <td className="table-ends">{entry.deliverySourceType}</td>
                  <td className="table-ends">{entry.engagementBasedTactic}</td>
                  <td className="table-ends">{entry.vehicle}</td>
                  <td className="table-ends">{entry.isCap ? "*" : "-"}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <div>
                <span className="no-data">No Data Available</span>
              </div>
            </tbody>
          )
        ) : (
          <LdsLoadingSpinner
            size={50}
            animationSpeed={500}
            style={{
              zIndex: "3",
              position: "absolute",
              marginLeft: "47vW",
              marginTop: "3rem",
              height: "20rem",
            }}
          />
        )}
      </LdsTable>
      <div className="d-flex align-center">
        <LdsButton
          className="compact outlined paginationLastFirstBtn"
          onClick={() => firstPage()}
        >
          First
        </LdsButton>

        <LdsPagination
          style={{ color: "red !important" }}
          onLinkClick={clickLink}
          onNextClick={clickNext}
          onPrevClick={clickPrev}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          pageRangeVisible={10}
        />

        <LdsButton
          className="compact outlined paginationLastFirstBtn"
          onClick={() => lastPage()}
        >
          Last
        </LdsButton>

        &nbsp;
        <div id="pagelengthDropDown">
          <label>
            <select value={getNoOfRec} onChange={(e) => setNoOfRec(e.target.value)}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </label>
          &nbsp;
          <span> Rows per page:{getNoOfRec}</span>

          <div>
            {/* Showing {startRow + 1}-{endRow} of {data.length} results */}
          </div>

        </div>
      </div>
      <HistoryModal
        modalOpen={isAuditModalOpen}
        setAuditIsModalOpen={setAuditIsModalOpen}
        tacticId={curTacticId}
        sysName="omms"
      />
    </>
  );
}