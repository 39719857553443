import { mkConfig, generateCsv, download } from 'export-to-csv';

const ExportDataCSV = (sListDetails, fileName) => {

    const fieldOrderForTaccT = [
        'status',
        'country_names',
        'product_name',
        'category_id',
        'category',
        'subcategory_id',
        'subcategory',
        'category_definition',
        'subcategory_definition',
        'vvpm_flag',
        'vvmc_flag',
        'aem_flag',
        'created_by',
        'created_date',
        'created_date',
        'active_flag',
        'active_flag_date',
        'group_purpose_code',
    ]

    const fieldOrderForOmms = [
        "deliverySource",
        "supplierName",
        "deliverySourceType",
        "deliveryChannel",
        "platform",
        "contactType",
        "engagementClassification",
        "metricLabel",
        "hierarchyClassification",
        "vehicle",
        "metricDefinition",
        "active_flag",
        "isCap",
        "approvalStatus",
        "isPersonalPromotional",
        "isDigital",
        "created_by",
        "created_date",
        "isUpdated",
        "updated_by",
        "updated_date",
    ];

    const fieldOrderArchiveOmms = [
            "deliverySource",
            "supplierName",
            "deliverySourceType",
            "deliveryChannel",
            "platform",
            "contactType",
            "engagementClassification",
            "vehicle",
            "metricLabel",
            "hierarchyClassification",
            "metricDefinition",
            "active_flag",
            "isCap",
            "approvalStatus",
            "isPersonalPromotional",
            "isDigital",
            "created_by",
            "created_date",
            "isUpdated",
            "updated_by",
            "updated_date",
    ];
    const fieldOrderForTaccTBU = [
        'status',
        'business_unit',
        'created_date',
        'updated_date',
        'active_flag',
    ];

    const fieldOrderForTaccTGF = [
        'status',
        'grammatical_form',
        'grammatical_form_operational_definition',
        'created_date',
        'updated_date',
        'active_flag',
    ];

    const fieldOrderForTaccTTonality = [
        "status",
        "tonality_values",
        "tonality_operational_definition",
        "created_date",
        "updated_date",
        "active_flag",
        "taxonomy_ccb_status",
        "omnichannel_managers_status",
    ];

    const d = new Date();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const date = d.getFullYear().toString().padStart(2, '0') +"-"+ 
    month +"-"+ 
   (d.getDate()).toString().padStart(2, '0') + "_" +
   (d.getHours()).toString().padStart(2, '0') +
   (d.getMinutes()).toString().padStart(2, '0');
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: false,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: fileName + `_${date}`,
    };

    const reorderData = sListDetails.map(record => {
        const reorderRecord = {};

        const fileOrder = (fileName === "OMMS_ArchiveList" ? fieldOrderArchiveOmms : fileName === "TACCT_BlueList" ? fieldOrderForTaccT : fileName === "OMMS_CampaignList" ? fieldOrderForOmms : fileName === "TACCT_businessunit" ? fieldOrderForTaccTBU : fileName === "TACCT_gramform" ? fieldOrderForTaccTGF : fileName === "TACCT_tonality" ? fieldOrderForTaccTTonality : '' )
        fileOrder.forEach(field => {
            reorderRecord[field] = record[field];
        });
        return reorderRecord
    })

    const csvConfig = mkConfig(options);
    const csv = generateCsv(csvConfig)(reorderData);
    download(csvConfig)(csv);
}

export default ExportDataCSV;
